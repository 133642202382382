<template>
  <div class="flex grow">
    <div class="relative hidden w-1/3 md:block">
      <img src="/login.jpeg" class="h-screen object-cover" />
      <div class="absolute bottom-10 left-10 text-sm">
        <p class="font-bold text-white/90">Burano</p>
        <p class="text-white/80">Venice, Italy</p>
      </div>
    </div>
    <div class="flex grow flex-col items-center justify-center p-8">
      <div class="w-full max-w-sm">
        <slot />
      </div>
    </div>
  </div>
</template>
